import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import tenantDetails from './tenantDetails';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
import leaseMasterAgreement from '../../leases/masterAgreement';
import leaseUnit from '../../masterLease/leaseUnit';
import party from '../../../../generalLedger/party';
export default {
  name: 'masterAgreement',
  watch: {
    currentPage: function() {
      this.getTenantSchuduleList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTenantSchuduleList();
    }
  },
  components: {
    commonHelper,
    tenantDetails,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication,
    leaseMasterAgreement,
    leaseUnit,
    party
  },
  data() {
    return {
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      leasePrj: {
        value: null,
        text: null
      },
      leaseTwr: {
        value: null,
        text: null
      },
      leaseFloor: {
        value: null,
        text: null
      },
      schedule: {
        value: null,
        text: null
      },
      unsubscribe: null,
      component: null,
      showCommonModal: false,
      showAddLeaseUnitModal: false,
      showDetails: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      unitName: null,
      loader: false,
      unitDisplay: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      leaseUnitRowDetails: null,
      lease_master_agreement_hdr_id: null,
      lease_master_agreement_name: null,
      party_id: null,
      party_name: null,
      appStatus: {
        value: null,
        text: null
      },
      lease_prj_unit_id: null,
      lease_prj_unit_name: null,
      lease_tenant_agreement_no: '',
      gridData: [],
      gridFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'lease_prj_name',
          label: 'Project'
        },
        {
          key: 'lease_tenant_agreement_no',
          label: 'Lease Tenant No.'
        },
        {
          key: 'lease_agreement_no',
          label: 'MLA No.'
        },
        {
          key: 'party_id'
        },
        {
          key: 'party'
        },
        {
          key: 'customer_site_id',
          label: 'Party Site Id '
        },
        {
          key: 'customer_site',
          label: 'Party Site'
        },
        {
          key: 'customer_site_address',
          label: 'Party Site Address'
        },
        {
          key: 'payment_term'
        },
        {
          key: 'lease_unit_name',
          label: 'Lease Office'
        },
        {
          key: 'lease_schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'recovery_type_vset_meaning',
          label: 'Recovery Type'
        },
        {
          key: 'approval_status'
        }
      ],
      scheduleHdrId: null,
      showHistoryBtn: false,
      showHistory: false,
      historyType: null,
      showOpenKmModal: false,
      openkmAuthToken: null,
      selectedGridData: null,
      isViewMore: false,
      depVsetParam: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'download' &&
          !this.showAddLeaseUnitModal &&
          !this.showDetails
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'leaseUnit/getLeaseUnitList',
            'lease-unit',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    searchGridData() {
      this.getTenantSchuduleList();
      this.isViewMore = false;
    },
    getTenantSchuduleList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.legalEntity.value,
        ou_id: this.operatingUnit.value,
        lease_prj_id: this.leasePrj.value,
        lease_master_agreement_hdr_id: this.lease_master_agreement_hdr_id,
        lease_tenant_agreement_no: this.lease_tenant_agreement_no,
        lease_unit_name: this.lease_prj_unit_name,
        party: this.party_name,
        approval_status: this.appStatus.value,
        lease_schedule_type_vset: this.schedule.value
      };
      this.loader = true;
      this.$store
        .dispatch('leaseSchedule/getTenantScheduleList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearSearchFilters() {
      this.lease_master_agreement_hdr_id = null;
      this.lease_master_agreement_name = null;
      this.lease_tenant_agreement_no = null;
      this.party_id = null;
      this.party_name = null;
      this.lease_prj_unit_id = null;
      this.lease_prj_unit_name = null;
      this.appStatus = {
        value: null,
        text: null
      };
      this.schedule = {
        value: null,
        text: null
      };
      this.legalEntity = {
        value: null,
        text: null
      };
      this.operatingUnit = {
        value: null,
        text: null
      };
      this.leasePrj = {
        value: null,
        text: null
      };
      this.gridData = [];
      this.showHistoryBtn = false;
      this.totalRows = null;
      this.currentPage = 1;
      this.isViewMore = false;
    },
    hideAddLeaseModal() {
      this.showAddLeaseUnitModal = false;
    },
    hideCommonModal(flag, modalType) {
      this.showCommonModal = flag;
      this.component = modalType;
    },
    selectedLeaseMaster(item) {
      this.lease_master_agreement_hdr_id = item.lease_master_agreement_hdr_id;
      this.lease_master_agreement_name = item.lease_agreement_no;
      this.showCommonModal = false;
    },
    getSelectedUnit(item) {
      this.lease_prj_unit_name = item.unit_name;
      this.lease_prj_unit_id = item.lease_prj_unit_id;
      this.showCommonModal = false;
    },
    getSelectedPartyCustomer(item) {
      this.party_id = item.party_id;
      this.party_name = item.party_name;
      this.showCommonModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.operatingUnit = {
          text: null,
          value: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.parent_value_set_id = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leasePrj = {
          value: item.value_set_dtl_id,
          text: item.lease_prj_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.APPROVAL_STATUS) {
        this.appStatus = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_SCHEDULE_TYPE
      ) {
        this.schedule = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    rowSelected(item) {
      this.scheduleHdrId = item.lease_tenant_schedule_hdr_id;
      this.showDetails = true;
    },
    closeLeaseUnitDetail() {
      this.showDetails = false;
    },
    updateLeaseUnitList() {
      this.gridData = [];
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.selectedGridData = {
              ...this.selectedLeaseDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null
            };
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.selectedGridData = {
        ...this.selectedLeaseDetails,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      };
      this.showHideHistory(true, 'uploadDocument');
    },
    selectedLease(item) {
      this.selectedLeaseDetails = Object.assign({}, item);
      this.showHistoryBtn = true;
      this.scheduleHdrId = item.lease_tenant_schedule_hdr_id;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.legalEntity = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'OU_LIST') {
        this.operatingUnit = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'LEASE_PROJECT_LIST') {
        this.leasePrj = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'APPROVAL_STATUS') {
        this.appStatus = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'LEASE SCHEDULE TYPE') {
        this.schedule = {
          value: null,
          text: null
        };
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
